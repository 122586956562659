@import '../../resources/vars';
@import '../../resources/mixins';

.wrapper {
  order: var(--tabs-order, -1);
  background: var(--tabs-background, transparent);
  padding: var(--tabs-padding, 0);

  @include respond-to(sm, up) {
    padding: var(--tabs-padding-m, 0);
  }

  @include respond-to(lg, up) {
    padding: var(--tabs-padding-l, 0);
  }
}


.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: var(--tabs-justify-content, flex-start);
  gap: var(--tabs-gap, 0);

  @include respond-to(sm, up) {
    gap: var(--tabs-gap-m, 0);
  }

  @include respond-to(lg, up) {
    gap: var(--tabs-gap-l, 0);
  }
}

@mixin link($link-key) {
  cursor: pointer;
  padding: var(--tabs-tab-#{$link-key}-padding, 0);
  border-bottom: var(--tabs-tab-#{$link-key}-border-bottom, 0);
  background: var(--tabs-tab-#{$link-key}-background, transparent);
  color: var(--tabs-tab-#{$link-key}-color, inherit);
  &:hover {
    color: var(--tabs-tab-#{$link-key}-color-hover, inherit);
  }
  @include with-styled-text(tabs-tab-#{$link-key});
}

.link-on {
  @include link(on);
}

.link-off {
  @include link(off);
}
