@import '../resources/vars';
@import '../resources/mixins';

.videoOverlay {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--video-dimmer-background);
  border-radius: var(--video-border-radius);
}

.safeArea {
  width: 100%;
  display: flex;
  flex-direction: var(--video-flex-direction, column);

  @include respond-to(sm, up) {
    flex-direction: var(--video-flex-direction-m, column);
  }

  @include respond-to(lg, up) {
    flex-direction: var(--video-flex-direction-l, column);
  }
}

.videoContainer {
  display: flex;
  flex-grow: 1;
}

.videoContainer > div {
  padding: var(--video-container-padding);

  @include respond-to(sm, up) {
    padding: var(--video-container-padding-m, var(--video-container-padding));
}

  @include respond-to(lg, up) {
    padding: var(--video-container-padding-l, var(--video-container-padding));
  }
}

.videoContainer div {
  display: flex;
  flex-grow: 1;
}

.videoContainer iframe {
  height: 100%;
  width: 100%;
  border: none;
}

.videoClose {
  display: flex;
  align-items: var(--video-close-align-items, center);
  justify-content: var(--video-close-justify-content, center);
  padding: var(--video-close-padding);

  @include respond-to(sm, up) {
    align-items: var(--video-close-align-items-m, center);
    justify-content: var(--video-close-justify-content-m, center);
    padding: var(--video-close-padding-m);
  }

  @include respond-to(lg, up) {
    align-items: var(--video-close-align-items-l, center);
    justify-content: var(--video-close-justify-content-l, center);
    padding: var(--video-close-padding-l);
  }
}

.videoCloseIcon div {
  cursor: pointer;
  padding: var(--video-close-icon-bg-padding);
  background: var(--video-close-icon-bg-background);
  border: var(--video-close-icon-bg-border);
  border-radius: var(--video-close-icon-bg-border-radius);
  box-shadow: var(--video-close-icon-bg-box-shadow);

  svg {
    width: var(--video-close-icon-size);
    height: var(--video-close-icon-size);
    margin: var(--page-actions-icon-margin);

    path {
      fill: var(--video-close-icon-color);
    }
  }
}
